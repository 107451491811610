<template>
    <div class="share">
        <img :src="data" id="bg">
    </div>
</template>

<script>
  import {getBill2} from "@/api/goods";
  import {getWxConfig} from "@/api/wx";

  export default {
    name: "Hai",
    data () {
      return {
        id: 0,
        data: "",
        qrStyle: {
        }
      }
    },
    methods: {
      async loadWxConfig () {
        const url = window.location.href;
        const res = await getWxConfig(url);
        if (res.success == true) {
          wx.config({
            debug: false,
            appId: res.data.appId,
            timestamp: res.data.timestamp,
            nonceStr: res.data.nonceStr,
            signature: res.data.signature,
            jsApiList: [
              'hideMenuItems',
            ]
          });
        }
      },
      async loadGoodsInfo () {
        getBill2(this.id).then(data => {
          if (data.success == true) {
            this.data = data.data.data;
            this.drawer()
          }
        })
      },
      drawer() {
        var _this = this;
        var bg = document.getElementById("bg");
        bg.onload = function () {
          const h = bg.clientHeight;
          const w = bg.clientHeight;
        }
      },
      hide() {
        wx.ready(function(){
          wx.hideMenuItems({
            menuList: [
              "menuItem:share:appMessage",
              "menuItem:share:timeline",
              "menuItem:share:qq",
              "menuItem:share:weiboApp",
              "menuItem:favorite",
              "menuItem:share:facebook",
              "menuItem:share:QZone"
            ]
          });
        });
      }
    },
    created () {
      this.id = this.$route.params.id;
      this.loadWxConfig();
      this.hide();
      this.loadGoodsInfo()
    }
  }
</script>

<style scoped>
    .share img {
        width: 100%;
    }
</style>
